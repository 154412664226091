var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section container"},[_c('h1',[_vm._v("Meus Cursos")]),_c('hr'),_vm._l((_vm.courses),function(course){return _c('div',{key:course._id,class:[
    course.completed ? 'box-green' : '',
    'box',
    !course.active ? 'transp' : '' ]},[(!course.active)?_c('div',{staticClass:"icon-custom"},[_c('b-icon',{attrs:{"pack":"fa","icon":"lock"}})],1):_vm._e(),(course.completed && course.active)?_c('div',{staticClass:"icon-custom green"},[_c('b-icon',{attrs:{"pack":"fa","type":"is-success","icon":"check"}})],1):_vm._e(),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-10 img opacity-custom"},[_c('img',{attrs:{"src":course.course.instructor.image,"alt":"imagem professor"}}),_c('div',[(course.active)?_c('router-link',{attrs:{"to":{
            name: 'userCourseUser',
            params: { name: course.course._id },
          },"tag":"h3"}},[_vm._v(_vm._s(course.course.name))]):_c('h4',{staticClass:"has-text-weight-bold"},[_vm._v("\n            "+_vm._s(course.course.name)+"\n          ")]),_c('span',[_vm._v(_vm._s("Professor: " + course.course.instructor.name))]),(course.active)?_c('router-link',{staticClass:"icon-control animated infinite pulse slow",attrs:{"to":{
            name: 'userCourseUser',
            params: { name: course.course._id },
          }}},[_c('b-icon',{attrs:{"pack":"fa","type":"is-success","icon":"arrow-circle-right"}}),_c('span',[_vm._v("Acessar curso")])],1):_vm._e(),(course.completed && course.hasCertification)?_c('a',{staticClass:"icon-control link-redirect animated infinite pulse slow",attrs:{"href":_vm.url,"target":"_blank","rel":"noopener noreferrer"}},[_c('b-icon',{attrs:{"pack":"fa","type":"is-success","icon":"arrow-circle-right"}}),_c('span',[_vm._v("Acessar Certificação")])],1):_vm._e()],1)]),_c('div',{staticClass:"column medalha"},[(course.completed)?_c('div',[_c('img',{attrs:{"src":require("@/assets/img/medalhaOn.png"),"alt":"imagem medalha"}}),_c('span',{staticStyle:{"color":"#597aff"}},[_vm._v(_vm._s(course.examMark))]),_c('p',{staticClass:"link",on:{"click":function($event){return _vm.certificado(course)}}},[_vm._v("Certificado Curso")])]):_c('div',{staticClass:"off"},[_c('img',{attrs:{"src":require("@/assets/img/medalhaOff.png"),"alt":"imagem medalha"}})])])]),_c('div',{staticClass:"bar-progress opacity-custom "},[_c('span',[_vm._v("\n        "+_vm._s(course.progress ? course.progress.toFixed(2) + "%" : "0%")+"\n      ")]),_c('div',{style:(course.progress ? 'width:' + course.progress.toFixed(2) + '%' : '')})])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.coursenull),expression:"coursenull"}]},[_c('h1',{staticClass:"oops"},[_c('b',[_vm._v("OOPS!")]),_vm._v(" você não tem nenhum curso,\n      "),_c('router-link',{attrs:{"tag":"span","to":"/cursos"}},[_vm._v("clique aqui")]),_vm._v("para\n      comprar um curso!\n    ")],1)]),_c('Certificado',{directives:[{name:"show",rawName:"v-show",value:(_vm.userCert),expression:"userCert"}],attrs:{"user":_vm.userCert}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }